<template>
  <div class="drawer">
    <input id="my-drawer-3" type="checkbox" class="drawer-toggle" /> 
    <div class="drawer-content flex flex-col">
      <!-- Navbar -->
      <navbar-section />
      <div>
        <div class="container mx-auto">
          <p>About Us</p>
        </div>
        <global-footer />
      </div>
    </div> 
    <side-nav-section />
  </div>
</template>

<script>
import NavbarSection from '@/components/NavbarSection.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import SideNavSection from '@/components/SideNavSection.vue'
export default {
  data(){
    return {
      sample: 'Roman'
    }
  },
  components: { GlobalFooter, NavbarSection, SideNavSection },
  mounted(){
    this.$router.push({name: "home"});
  }

}
</script>

<style>

</style>
