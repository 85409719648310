<template>
    <section>
        <h3 class="text-4xl font-bold text-center my-5">Our Products</h3>
        <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-4">

            <div class="card card-compact bg-back-light text-white shadow-xl">
                <figure><img src="@/assets/img/RS-iVoting.jpg" alt="Shoes" /></figure>
                <div class="card-body">
                    <h2 class="card-title">iVoting</h2>
                    <p>iVoting™ is a universal voting platform designed to be a lasting global solution to all voting modes - including surveys and opinion polls, to achieve free, fair, credible, convenient & secure voting. This coupled with its ease of use brings convenience to the voter to their doorstep. The system is designed for everyone regardless of location, race, class and network coverage.</p>
                    <div class="card-actions justify-end">
                        <button class="btn btn-primary">Buy Now</button>
                    </div>
                </div>
            </div>

            <div class="card card-compact bg-back-light text-white shadow-xl">
                <figure><img src="@/assets/img/RS-Mental-Wellness-Center.png" alt="Shoes" /></figure>
                <div class="card-body">
                    <h2 class="card-title">Mental Wellness Center</h2>
                    <p>MWC™ or Mental Wellness Center™ is an online, secure, convenient service that links you to qualified psycho-social counsellors, whom you interact with anonymously, providing you with on-the-go counselling from your phone. This service was created to provide afforable counselling services because we understand it's okay not to be okay.</p>
                    <div class="card-actions justify-end">
                        <button class="btn btn-primary">Buy Now</button>
                    </div>
                </div>
            </div>

            <div class="card card-compact bg-back-light text-white shadow-xl">
                <figure><img src="@/assets/img/RS-iVoting.jpg" alt="Shoes" /></figure>
                <div class="card-body">
                    <h2 class="card-title">iVoting</h2>
                    <p>iVoting™ is a universal voting platform designed to be a lasting global solution to all voting modes - including surveys and opinion polls, to achieve free, fair, credible, convenient & secure voting. This coupled with its ease of use brings convenience to the voter to their doorstep. The system is designed for everyone regardless of location, race, class and network coverage.</p>
                    <div class="card-actions justify-end">
                        <button class="btn btn-primary">Buy Now</button>
                    </div>
                </div>
            </div>

        </div>

        
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>