<template>
    <div class="w-full navbar bg-main text-white dark:bg-back-dark dark:text-white">
        <div class="flex-none lg:hidden">
            <label for="my-drawer-3" class="btn btn-square btn-ghost">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            </label>
        </div> 
        <router-link :to="{name: 'home'}"><img src="@/assets/img/logo-white.png" alt="" width="30" height="25"/></router-link>
        <div class="flex-1 px-2 mx-2"></div>
        <div class="flex-none hidden lg:block">
        <ul class="menu menu-horizontal">
            <li><router-link :to="{name: 'about'}">About</router-link></li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>