<template>
    <div class="container flex flex-col flex-col-reverse px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:space-y-0 md:flex-row">
        <div class="flex flex-col space-y-12 md:w-1/2 mx-3 mt-5">
            <h3 class="text-3xl font-bold text-center text-iv-color">iVoting</h3>
            <p>iVoting™ is a universal voting platform designed to be a lasting global solution to all voting modes - including surveys and opinion polls, to achieve free, fair, credible, convenient & secure voting. This coupled with its ease of use brings convenience to the voter to their doorstep. The system is designed for everyone regardless of location, race, class and network coverage.</p>
        </div>
        <div class="flex flex-col space-y-8 md:w-1/2">
            <div class="card card-compact bg-white shadow-xl">
                <figure><img src="@/assets/img/RS-iVoting.jpg" alt="Shoes" /></figure>
                <div class="card-body">
                    <div class="card-actions justify-end">
                        <a href="https://ivoting.co.ke" target="_blank" class="btn bg-iv-color border-iv-color rounded-full hover:border-iv-color text-white hover:bg-white hover:text-iv-color">More...</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>