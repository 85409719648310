<template>
    <div class="drawer">
        <input id="my-drawer-3" type="checkbox" class="drawer-toggle" /> 
        <div class="drawer-content flex flex-col">
            <navbar-section />
            <div>
                <hero-section />
                <div class="container mx-auto">
                    <about-section />
                    <div class="sm:mx-4 md:mx-4">
                        <tech-stack-section />
                    </div>
                </div>
                <call-to-action />
                <div class="container mx-auto">  
                    <h3 class="text-4xl font-bold text-center my-5">Our Products</h3>
                    <i-voting-component />
                    <m-w-c-component />
                </div>
                <h3 class="text-4xl font-bold text-center my-5">Stats</h3>
                <stats-section />
                <global-footer />
            </div>
        </div> 
        <side-nav-section />
    </div>
</template>

<script>
import AboutSection from './AboutSection.vue'
import CallToAction from './CallToAction.vue'
import GlobalFooter from './GlobalFooter.vue'
import HeroSection from './HeroSection.vue'
import IVotingComponent from './iVotingComponent.vue'
import MWCComponent from './MWCComponent.vue'
import NavbarSection from './NavbarSection.vue'
import ProductsSection from './ProductsSection.vue'
import SideNavSection from './SideNavSection.vue'
import StatsSection from './StatsSection.vue'
import TechStackSection from './TechStackSection.vue'
export default {
  components: { HeroSection, AboutSection, TechStackSection, ProductsSection, GlobalFooter, CallToAction, IVotingComponent, MWCComponent, NavbarSection, SideNavSection, StatsSection },

}
</script>

<style>
       
</style>