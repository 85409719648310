<template>
    <div class="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:space-y-0 md:flex-row">
        <div class="flex flex-col space-y-12 md:w-1/2 mx-3 mt-5">
            <div class="card card-compact bg-mwc-color shadow-xl">
                <figure><img src="@/assets/img/RS-Mental-Wellness-Center.png" alt="Shoes" /></figure>
                <div class="card-body">
                    <div class="card-actions justify-end">
                        <a href="https://mwc.roman-solutions.com" target="_blank" class="btn rounded-full border-white bg-white text-mwc-color transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110">More...</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-8 md:w-1/2">
            <h3 class="text-3xl font-bold text-center text-mwc-color">Mental Wellness Center</h3>
            <p>MWC™ or Mental Wellness Center™ is an online, secure, convenient service that links you to qualified psycho-social counsellors, whom you interact with anonymously, providing you with on-the-go counselling from your phone. This service was created to provide afforable counselling services because we understand it's okay not to be okay.</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>