<template>
    <section class="my-5">
        <div class="grid md:grid-cols-4 sm:grid-cols-1 gap-4">
            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div class="stat">
                        <div class="stat-title">Clients</div>
                        <div class="stat-value">28</div>
                    </div>
                </div>
            </div>


            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div class="stat">
                        <div class="stat-title">Websites / Apps</div>
                        <div class="stat-value">18</div>
                        <div class="stat-desc">↗︎ 3 ongoing</div>
                    </div>
                </div>
            </div>

            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div class="stat">
                        <div class="stat-title">Mobile Applications</div>
                        <div class="stat-value">6</div>
                        <div class="stat-desc">↗︎ 1 ongoing</div>
                    </div>
                </div>
            </div>

            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div class="stat">
                        <div class="stat-title">Desktop Apps</div>
                        <div class="stat-value">4</div>
                    </div>
                </div>
            </div>


        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>