<template>
  <home-component />
</template>

<script>
import HomeComponent from '@/components/HomeComponent.vue'
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: { HomeComponent },
  created(){
    document.title = this.$route.meta.title;
  },
}
</script>

    HomeComponent