<template>
    <section id="call-to-action" class="bg-base-200 dark:bg-main my-10 p-4" style="border-radius: 15px 50px;">
        <div class="container px-10 flex flex-col items-center justify-between px-6 py-24 ax-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
            <h2 class="text-5xl font-bold leading-tight text-center text-main dark:text-white md:text-4xl md:max-w-xl md:text-left">Get In Touch With Us For A Quote or A Demonstration</h2>
            <div>
                <a  href="mailto:hello@roman-solutions.com?subject=Roman Solutions' Enquiry&body=Hello, I would like to ..." class="p-3 bg-main dark:bg-base-200 text-white shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 rounded-full hover:bg-slate-900 hover:text-white">Get Started</a>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>