<template>
    <section>
        <div class="hero min-h-screen bg-base-200">
            <div class="hero-content flex-col lg:flex-row-reverse items-center">
                <img src="@/assets/img/hero_image.svg" class="max-w-sm rounded-lg shadow-2xl p-5" />
                <div>
                    <h1 class="text-5xl font-bold mr-5">Reliable Technology</h1>
                    <p class="py-6">We offer ready-made and customized software solutions to improve your business and personal life</p>
                    <button class="btn bg-main text-white border-main rounded-full">Get Started</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>