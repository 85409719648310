<template>
    <div class="drawer-side">
        <label for="my-drawer-3" class="drawer-overlay"></label> 
        <ul class="menu p-4 w-80 bg-base-100">
            <li><router-link :to="{name: 'about'}">About</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>