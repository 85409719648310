<template>
    <section>
        <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div>
                        <span class="inline-flex items-center justify-center p-2 bg-white dark:bg-back-dark text-main rounded-md shadow-lg">
                            <i class="material-icons  text-slate-50 dark:text-slate-50">desktop_windows</i>
                        </span>
                    </div>
                    <h2 class="card-title">Desktop Apps</h2>
                    <p>Java, Python & Electron</p>
                </div>
            </div>

            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div>
                        <span class="inline-flex items-center justify-center p-2 bg-white dark:bg-back-dark text-main rounded-md shadow-lg">
                            <i class="material-icons  text-slate-50 dark:text-slate-50">phone_iphone</i>
                        </span>
                    </div>
                    <h2 class="card-title">Mobile Apps</h2>
                    <p>Kotlin, Swift, Flutter and React Native</p>
                </div>
            </div>

            <div class="card bg-main dark:bg-base-200 text-white">
                <div class="card-body">
                    <div>
                        <span class="inline-flex items-center justify-center p-2 bg-white dark:bg-back-dark text-main rounded-md shadow-lg">
                            <i class="material-icons  text-slate-50 dark:text-slate-50">language</i>
                        </span>
                    </div>
                    <h2 class="card-title">Web Apps</h2>
                    <p>Angular, React, Vue, NodeJS & Firebase</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>