import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Roman Solutions - For Humanity',
      metaTags: [
        {
          name: 'description',
          content: "Roman Solutions is a startup founded on the principle of problem solving through technology. Our core technologies center around desktop development, mobile app development, web development and artificial intelligence with the end goal of solving humanity's greatest problems"
        },
        {
          property: 'og:description',
          content: "Roman Solutions is a startup founded on the principle of problem solving through technology. Our core technologies center around desktop development, mobile app development, web development and artificial intelligence with the end goal of solving humanity's greatest problems"
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'About Us Roman Solutions',
      metaTags: [
        {
          name: 'description',
          content: "Roman Solutions is a startup founded on the principle of problem solving through technology. Our core technologies center around desktop development, mobile app development, web development and artificial intelligence with the end goal of solving humanity's greatest problems"
        },
        {
          property: 'og:description',
          content: "Roman Solutions is a startup founded on the principle of problem solving through technology. Our core technologies center around desktop development, mobile app development, web development and artificial intelligence with the end goal of solving humanity's greatest problems"
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
