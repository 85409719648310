<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

html {
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  font-family: Montserrat;
}
</style>
