<template>
    <section class="mb-3">
        <div class="container flex flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:space-y-0 md:flex-row">
            <div class="flex flex-col space-y-12 md:w-1/2">
                <h2 class="max-w-md text-4xl font-bold text-center md:text-left text-dark dark:text-white">
                    Who, What, Why, How?
                </h2>
                <p class="max-w-lg text-center md:text-left text-dark dark:text-white">Roman Solutions is a startup founded on the principle of problem solving through technology. Our core technologies center around desktop development, mobile app development and web development with the end goal of solving humanity's greatest problems through technology.</p>
            </div>

            <div class="flex flex-col space-y-8 md:w-1/2">
                <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6">
                    <div class="rounded-l-full rounded-r-full bg-blue-900 md:bg-transparent">
                        <div class="flex items-center space-x-2">
                            <div class="px-4 py-2 text-white rounded-full md:py-1 bg-main text-center">Mission</div>
                            <!-- <h3 class="text-base font-bold md:mb-4 md:hidden">Desktop Apps</h3> -->
                        </div>
                    </div>
                    <!-- <div>
                        <h3 class="hidden mb-4 text-lg font-bold md:block"> Desktop Apps</h3>
                    </div> -->
                    <p class="text-start text-dark dark:text-white">We aim to be a strong contributor in the tech space by innovating and providing solutions to the individuals as well as enterprises.</p>
                </div>
                <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6">
                    <div class="rounded-l-full rounded-r-full bg-blue-900 md:bg-transparent">
                        <div class="flex items-center space-x-2">
                            <div class="px-4 py-2 text-white rounded-full md:py-1 bg-main text-center">Vision</div>
                        </div>
                    </div>
                    <p class="text-start text-dark dark:text-white">Our end game is to solve humanity's greatest issues like cancer, hunger, climate change... every line of code written is geared to this purpose.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>